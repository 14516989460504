import { QRCodeSVG } from 'qrcode.react';
import { isEmpty, isNil, isNotEmpty } from 'rambdax';
import { useEffect, useReducer, useRef, useState } from 'react';

import {
  AssetResponse,
  QrCodeData,
  useCreateWalletVerificationRequest,
  useGetAllAsset,
  useGetPortfolio,
  useIsWithdrawalAllowedWallet,
  useVerifyAddressWallet,
  useWithdrawalWallet,
  VerifyAddressWithSignedFormResult,
  WithdrawalAllowanceStatusType,
} from '@ping/api';
import ArrowLeft from '@ping/assets/Icon/arrowLeft.svg';
import { ContactCrypto, TooltipWithdrawalLimitsInfo, WithdrawTierUpgradingStepModal } from '@ping/components';
import { t } from '@ping/helpers';
import * as gtm from '@ping/helpers/gtm.helper';
import { useConversionAssetToFiat, useDebounce, useMobile, useToggle, useWithdrawalLimits } from '@ping/hooks';
import {
  transferOnProcessingModalRemoteStateSelector,
  useModalStore,
  withdrawalTierUpgradeModalRemoteStateSelector,
} from '@ping/stores/modal.store';
import { AssetComboBox, Button, Divider, Popup, ProgressBar, Text, Toast } from '@ping/uikit';
import { DateTime, format } from '@ping/utils';
import { useNotificationWS } from '@ping/websockets';
import { NotificationEvents, WithdrawNotificationStatus } from '@ping/websockets/useNotificationWS';

import AddressComboBox from '../AddressComboBox';
import MobileConfirmation from '../MobileConfirmation';

import NetworkSelect from '@ping/components/CryptoTransaction/NetworkSelect';
import { LimitsWarningMessage } from './LimitsWarningMessage';

import { VerifyOwnership } from '@ping/components/CryptoTransaction/SendTransaction/VerifyOwnership';
import { modalKeys } from '@ping/constants/modal-keys.constant';
import { useRemoteModal } from '@ping/hooks';
import { ExternalWalletAddressReplace } from '@ping/pages/user/addressbook/components/ModalAddressVew/ExternalWalletAddressReplace';
import clsx from 'clsx';
import { LinkExternalWalletAddress } from '../LinkExternalWalletAddress';
import { ExternalCorePassAddressVerificationAlert } from './ExternalCorePassAddressVerificationAlert';
import { ExternalWalletAddressVerificationAlert } from './ExternalWalletAddressVerificationAlert';
import { validateAmount } from './validations.helper';
import style from './style.module.scss';
import { VerifyOwnershipLTC } from '@ping/components/CryptoTransaction/SendTransaction/VerifyOwnershipLTC';
import { ExternalWalletAddressVerificationLTCAlert } from './ExternalWalletAddressVerificationLTCAlert';

interface ISendTransactionProps {
  assetId?: string;
  onCancel(): void;
  onChangeView(view: MODAL_FULL_VIEW): void;
  onLTCVerify?: (data: VerifyAddressWithSignedFormResult) => void;
}

interface ISendTransactionState {
  assetId: string;
  assetAmount: number;
  address: string;
  balance: number;
  network: string;
}

interface IAllAssetsWithdrawInfo {
  withdrawFee: number;
  minWithdraw: number;
}

enum ESteps {
  SEND_TRANSACTION = 'SEND_TRANSACTION',
  SAVE_ADDRESS = 'SAVE_ADDRESS',
  WITHDRAWAL_CONFIRMATION = 'WITHDRAWAL_CONFIRMATION',
}

enum MODAL_FULL_VIEW {
  VERIFY_ADDRESS = 'verifyAddress',
  VERIFY_ADDRESS_LTC = 'verifyAddressLTC',
  QRCODE = 'qrcode',
}

const PER_TRANSACTION_ERROR_CODE = 70101;
const PER_MONTH_ERROR_CODE = 70103;
const PER_YEAR_ERROR_CODE = 70105;
const UPGRADE_TIER_ERROR_CODES = [70100, 70102, 70104];
const UPGRADE_TIER_SUB_ERROR_CODES = [PER_TRANSACTION_ERROR_CODE, PER_MONTH_ERROR_CODE, PER_YEAR_ERROR_CODE];

const SendTransaction = (props: ISendTransactionProps) => {
  const isMobile = useMobile();
  const { onChangeView, onLTCVerify } = props;
  const [confirmationLink, setConfirmationLink] = useState<Pick<QrCodeData, 'link' | 'expiration'>>({
    link: '',
    expiration: DateTime.minutes(5),
  });
  const [amountError, setAmountError] = useState<string | null>(null);
  const withdrawLimits = useWithdrawalLimits();

  const { data: portfolio } = useGetPortfolio();

  const { data: withdrawInfo } = useGetAllAsset({
    query: {
      staleTime: DateTime.days(1),
      select: ({ data: allAssets }) => {
        return allAssets.reduce(
          (map, asset) => map.set(asset.id, { withdrawFee: asset.withdrawal_fee, minWithdraw: asset.min_withdraw }),
          new Map<string, IAllAssetsWithdrawInfo>()
        );
      },
    },
  });

  const { mutate: sendTransaction } = useWithdrawalWallet();
  const [saveStep, setSaveStep] = useState<ESteps>(ESteps.SEND_TRANSACTION);
  const [withdrawConfirmationStep, setWithdrawConfirmationStep] = useState<ESteps>(ESteps.SEND_TRANSACTION);
  const [usersCurrentLimit, setUsersCurrentLimit] = useState(0);

  const withdrawalTierUpgradeModalRemoteState = useModalStore(withdrawalTierUpgradeModalRemoteStateSelector);
  const transferOnProcessingModalState = useModalStore(transferOnProcessingModalRemoteStateSelector);
  const { notificationData, notificationCallBack } = useNotificationWS();
  const { notificationData: onWalletNotification, notificationCallBack: onWalletVerificationCallback } =
    useNotificationWS();
  const targetTierToUpgrade = useRef<number>();
  const exceededLimitAmount = useRef<string>();
  const [isTooltipAddressVisible, toggleTooltipAddressVisible] = useToggle(false);
  const [viewState, setViewState] = useState<WithdrawalAllowanceStatusType>(null);
  const [modalFullView, setModalFullView] = useState<null | MODAL_FULL_VIEW>(null);
  notificationCallBack?.(NotificationEvents.OnWithdrawalStatusChanged);
  onWalletVerificationCallback?.(NotificationEvents.OnWalletVerified);
  const verifyAddressWallet = useVerifyAddressWallet();
  const addressVerificationFailedModal = useRemoteModal(modalKeys.ADDRESS_VERIFICATION_FAILED);
  const addressVerificationSuccessfulModal = useRemoteModal(modalKeys.ADDRESS_VERIFICATION_SUCCESSFUL);

  const { mutate: createWalletSignatureMutate, isLoading: isCreateWalletSignatureLoading } =
    useCreateWalletVerificationRequest();

  useEffect(() => {
    if (notificationData?.status === WithdrawNotificationStatus.SignatureVerified) {
      handleCloseWithdrawConfirmationStep();
      transferOnProcessingModalState.open();
    }
  }, [notificationData]);

  useEffect(() => {
    if (onWalletNotification) {
      handleCloseWithdrawConfirmationStep();
      Toast.success({ title: t('The address has been successfully saved') });
      setViewState(null);
      setModalFullView(null);
      addressVerificationSuccessfulModal.state.open();
    }
  }, [onWalletNotification]);

  const [state, setState] = useReducer(
    (prev: ISendTransactionState, next: Partial<ISendTransactionState>) => ({
      ...prev,
      ...next,
    }),
    {
      assetId: props.assetId,
      assetAmount: 0,
      balance: 0,
      address: '',
      network: '',
    }
  );

  const currencyConversionRate = useConversionAssetToFiat(state.assetId);
  const minWithdrawAmount = withdrawInfo?.get(state.assetId)?.minWithdraw;
  const enteredAmountValidation = validateAmount(state.assetAmount, minWithdrawAmount, state.balance);

  const unusedMonthlyLimitAmount =
    withdrawLimits.limitsList.totalMonthlyLimit - withdrawLimits.limitsList.totalCurrentMonthWithdrawalAmount;
  const limitsListArray = Object.entries(withdrawLimits.limitsList)
    .filter(([key]) => key !== 'totalCurrentMonthWithdrawalAmount')
    .map(([_, value]) => value)
    .concat(unusedMonthlyLimitAmount);

  const convertedTotalAmount = state.assetAmount * currencyConversionRate.rate;
  const amountIsHigherThanLimits = limitsListArray.filter(el => el < convertedTotalAmount);
  const amountIsHigherInt64 = convertedTotalAmount >= Number.MAX_SAFE_INTEGER;
  const exceedingCurrentLimit = convertedTotalAmount >= unusedMonthlyLimitAmount;

  useEffect(() => {
    setUsersCurrentLimit(
      limitsListArray.filter(el => el < convertedTotalAmount).reduce((a, b) => Math.max(a, b), -Infinity)
    );
  }, [withdrawLimits.limitsList, convertedTotalAmount]);

  const handleChange = newState => {
    const selectedAssetBalance = portfolio?.portfolioItems?.find(asset => asset.assetId === newState.assetId)?.amount;
    const validationError = validateAmount(newState.assetAmount, minWithdrawAmount, selectedAssetBalance) as string;
    newState.balance = selectedAssetBalance;
    setAmountError(validationError);
    setState(newState);
  };

  const handleOnSaveAddress = () => {
    setSaveStep(ESteps.SAVE_ADDRESS);
  };

  const handleOnSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    sendTransaction(
      {
        data: {
          assetId: state.assetId,
          address: state.address,
          amount: state.assetAmount,
          paymentSystem: state.network,
        },
      },
      {
        onSuccess: (data: string) => {
          gtm.withdrawalEvent(state.assetId);
          setConfirmationLink({ link: data, expiration: DateTime.minutes(5) });
          setWithdrawConfirmationStep(ESteps.WITHDRAWAL_CONFIRMATION);
        },
        onError: (error: any) => {
          const errorData = error?.response?.data;
          if (UPGRADE_TIER_ERROR_CODES.includes(errorData?.errorCode)) {
            const limitExceededData = errorData?.errors?.find(errorItem =>
              UPGRADE_TIER_SUB_ERROR_CODES.includes(+errorItem.key)
            );
            if (limitExceededData) {
              const targetTier = limitExceededData?.data?.SuggestedUserComplianceReviewRequestType?.split('To')[1];
              targetTierToUpgrade.current = targetTier.replace('Tier', '');
              exceededLimitAmount.current = limitExceededData?.data?.WithdrawalLimitAmount;
              withdrawalTierUpgradeModalRemoteState.open();
            }
          }
        },
      }
    );
  };

  const handleCloseSaveStep = () => {
    setSaveStep(ESteps.SEND_TRANSACTION);
  };

  const handleCloseWithdrawConfirmationStep = () => {
    setWithdrawConfirmationStep(ESteps.SEND_TRANSACTION);
  };

  const handleSaveAddress = () => {
    handleCloseSaveStep();
  };

  const withdrawalIsAllowedWallet = useIsWithdrawalAllowedWallet(
    {
      address: state.address,
      paymentSystem: state.network,
    },
    {
      query: {
        enabled: isNotEmpty(state.address) && isNotEmpty(state.network),
        cacheTime: DateTime.NEVER,
        staleTime: DateTime.NEVER,
      },
    }
  );

  const checkWithdrawalIsAllowed = async () => {
    if (isEmpty(state.address) || isEmpty(state.network)) {
      setViewState(null);
      return;
    }

    try {
      const { data } = await withdrawalIsAllowedWallet.refetch();
      setViewState(data.withdrawalAllowanceStatus);
    } catch (error) {
      setViewState(null);
    }
  };
  const checkWithdrawalIsAllowedDebounced = useDebounce(checkWithdrawalIsAllowed, 500);

  if (saveStep === ESteps.SAVE_ADDRESS) {
    const TitleComponent = () => (
      <>
        <ArrowLeft className={style['send-transaction__save-popup__back-arrow']} onClick={handleCloseSaveStep} />
        <span>{t('Save address')}</span>
      </>
    );
    return (
      <Popup title={<TitleComponent />} isOpen onClose={handleCloseSaveStep}>
        <ContactCrypto
          finishCallback={handleSaveAddress}
          defaultValues={{
            address: state.address,
            network: state.network,
          }}
        />
      </Popup>
    );
  }

  if (withdrawConfirmationStep === ESteps.WITHDRAWAL_CONFIRMATION) {
    return (
      <>
        {!isMobile && (
          <Popup isOpen onClose={handleCloseWithdrawConfirmationStep} fullscreen>
            <div className={style['send-transaction__withdrawal-confirmation']}>
              <div className={style['send-transaction__withdrawal-confirmation__block']}>
                <div className={style['send-transaction__withdrawal-confirmation__qr-wrapper']}>
                  <QRCodeSVG size={304} value={decodeURIComponent(confirmationLink.link)} level='L' />
                </div>

                <div className={style['send-transaction__withdrawal-confirmation__info']}>
                  <Text heading='3'>{t('Sign transaction')}</Text>
                  <Text body='regular'>{t('Scan QR code with your CorePass app to verify this transaction.')}</Text>
                </div>
              </div>
            </div>
          </Popup>
        )}

        {isMobile && (
          <Popup isOpen fullscreen>
            <MobileConfirmation
              onClose={handleCloseWithdrawConfirmationStep}
              confirmationLink={confirmationLink.link}
            />
          </Popup>
        )}
      </>
    );
  }

  const handleShowAsset = (asset: AssetResponse): boolean => {
    return Boolean(portfolio.portfolioItems.find(item => item.assetId === asset.id && asset.can_withdraw));
  };

  const handleMouseEnter = () => {
    if (!state.assetId && Boolean(portfolio?.portfolioItems.length)) {
      setAmountError(t('Select your token and then enter your value'));
    }
  };

  const handleLTCInstructions = () => {
    setModalFullView(MODAL_FULL_VIEW.VERIFY_ADDRESS_LTC);
    onChangeView(MODAL_FULL_VIEW.VERIFY_ADDRESS_LTC);
  };

  const handleLTCVerifyProcess = () => {
    createWalletSignatureMutate(
      { data: { walletAddress: state.address, paymentSystem: 'Litecoin' } },
      {
        onSuccess: data => {
          setModalFullView(null);
          onLTCVerify?.(data);
        },
        onError: () => {
          Toast.error({ title: 'Something went wrong, please try again' });
        },
      }
    );
  };

  const handleVerifyViewAddress = () => {
    setModalFullView(MODAL_FULL_VIEW.VERIFY_ADDRESS);
    onChangeView(MODAL_FULL_VIEW.VERIFY_ADDRESS);
  };

  const handleBackToSendTransaction = () => {
    setModalFullView(null);
    onChangeView(null);
    setViewState(null);
  };

  const handleVerifyAddress = () => {
    verifyAddressWallet.mutate(
      {
        data: {
          paymentSystem: state.network,
        },
      },
      {
        onSuccess: data => {
          if (data.qrCode) {
            setModalFullView(MODAL_FULL_VIEW.QRCODE);
            onChangeView(MODAL_FULL_VIEW.QRCODE);
            setConfirmationLink(data.qrCode);
            return;
          }
          /* show AddressVerificationFailedModal if possible */
          Toast.error({
            title: 'Complete verification on Corepass to continue',
            text: 'In order to continue go to your CorePass app and complete the proof of ownership verification. Once done, return here to continue.',
            options: { autoClose: 15000 },
          });
        },
        onError: () => {
          addressVerificationFailedModal.state.open();
        },
      }
    );
  };

  if (modalFullView === MODAL_FULL_VIEW.VERIFY_ADDRESS) {
    return (
      <>
        <ArrowLeft
          className={style['send-transaction__save-popup__back-arrow']}
          onClick={handleBackToSendTransaction}
        />
        <VerifyOwnership onSubmit={handleVerifyAddress} isLoading={verifyAddressWallet.isLoading} />
      </>
    );
  }

  if (modalFullView === MODAL_FULL_VIEW.VERIFY_ADDRESS_LTC) {
    return (
      <>
        <ArrowLeft
          className={style['send-transaction__save-popup__back-arrow']}
          onClick={handleBackToSendTransaction}
        />
        <VerifyOwnershipLTC onSubmit={handleLTCVerifyProcess} isLoading={isCreateWalletSignatureLoading} />
      </>
    );
  }

  if (modalFullView === MODAL_FULL_VIEW.QRCODE) {
    return (
      <div>
        <ArrowLeft
          className={clsx(style['send-transaction__save-popup__back-arrow'])}
          onClick={handleBackToSendTransaction}
        />
        <LinkExternalWalletAddress
          link={confirmationLink.link}
          defaultTime={DateTime.seconds(confirmationLink.expiration)}
          onFinish={handleVerifyAddress}
        />
      </div>
    );
  }

  return (
    <form
      className={style['send-transaction']}
      onSubmit={handleOnSubmit}
      data-is-assetId-selected={Boolean(state.assetId.length)}
    >
      <WithdrawTierUpgradingStepModal
        limit={exceededLimitAmount.current}
        currentUserTier={withdrawLimits.withdrawalLimit?.complianceTier}
        targetTier={targetTierToUpgrade.current}
      />

      <ProgressBar
        title={t('Monthly limit')}
        currentProgressValue={withdrawLimits.limitsList.totalCurrentMonthWithdrawalAmount}
        maxProgressValue={withdrawLimits.limitsList.totalMonthlyLimit}
        currency={withdrawLimits.selectedCurrency}
        isLoading={withdrawLimits.withdrawalLimitLoading}
        tooltipContent={
          <TooltipWithdrawalLimitsInfo
            perTransactionLimit={withdrawLimits.limitsList.perTransactionLimit}
            totalMonthlyLimit={withdrawLimits.limitsList.totalMonthlyLimit}
            totalYearlyLimit={withdrawLimits.limitsList.totalYearlyLimit}
            userTier={withdrawLimits.withdrawalLimit?.complianceTier}
            limitCurrency={withdrawLimits.selectedCurrency}
          />
        }
      />

      <div onMouseEnter={handleMouseEnter}>
        <AssetComboBox
          className={style['send-transaction__asset-combo-box']}
          defaultAssetId={state.assetId}
          defaultAmount={state.assetAmount}
          onChange={handleChange}
          filterFn={handleShowAsset}
          error={amountError || enteredAmountValidation}
        />
      </div>

      {amountIsHigherThanLimits.length != 0 && !amountIsHigherInt64 && (
        <LimitsWarningMessage
          usersCurrentLimit={usersCurrentLimit}
          perTransactionLimit={withdrawLimits.limitsList.perTransactionLimit}
          totalMonthlyLimit={withdrawLimits.limitsList.totalMonthlyLimit}
          totalYearlyLimit={withdrawLimits.limitsList.totalYearlyLimit}
          userSelectedCurrency={withdrawLimits.selectedCurrency}
          exceedingCurrentLimit={exceedingCurrentLimit}
        />
      )}

      {isNotEmpty(viewState) && (
        <section id={style['networks-dropdown']} data-disabled={!state.assetId || !state.assetAmount}>
          <NetworkSelect
            onChange={network => setState({ network })}
            assetId={state.assetId}
            isDisabled={!state.assetId || !state.assetAmount}
            label={t('Network')}
          />
        </section>
      )}

      <div
        className={style['send-transaction__address']}
        onMouseEnter={() => toggleTooltipAddressVisible(true)}
        onMouseLeave={() => toggleTooltipAddressVisible(false)}
      >
        <AddressComboBox
          assetId={state.assetId}
          network={state.network}
          defaultAddress={state.address}
          isDisabled={!state.assetId || !state.assetAmount || !state.network}
          onSave={handleOnSaveAddress}
          onChange={address => {
            setState({ address });
            checkWithdrawalIsAllowedDebounced();
          }}
        />
        {isTooltipAddressVisible && state.address && (
          <div className={style['send-transaction__address-tooltip']}>{state.address}</div>
        )}
      </div>

      {isNil(viewState) && state.assetId && (
        <>
          <Divider />
          <div className={style['send-transaction__info']}>
            <div className={style['send-transaction__info_label']}>
              <span className={style['send-transaction__info_fee-text']}>{t('Transaction fee')}</span>
              <span className={style['send-transaction__info_fee-text']}>
                {`${withdrawInfo?.get(state.assetId)?.withdrawFee} ${state.assetId.toUpperCase()} (
          ${currencyConversionRate.rate * withdrawInfo?.get(state.assetId)?.withdrawFee} ${
                  currencyConversionRate.selectedCurrency
                })`}
              </span>
            </div>

            {Boolean(state.assetAmount) && (
              <div className={style['send-transaction__info_label']}>
                <span className={style['send-transaction__info_label-amount']}>{t('Amount to receive')}</span>
                <span className={style['send-transaction__info_label-amount']}>
                  {`${format.crypto(
                    state.assetAmount - withdrawInfo?.get(state.assetId)?.withdrawFee,
                    state.assetId.toUpperCase()
                  )}`}
                </span>
              </div>
            )}
          </div>
        </>
      )}

      <footer className={style['send-transaction__actions']}>
        {viewState === WithdrawalAllowanceStatusType.VerificationRequired && (
          <ExternalWalletAddressVerificationAlert onClick={handleVerifyViewAddress} />
        )}
        {viewState === WithdrawalAllowanceStatusType.VerificationRequiredWithReplace && (
          <ExternalWalletAddressReplace onClick={handleVerifyViewAddress} />
        )}
        {viewState === WithdrawalAllowanceStatusType.ExternalCorePassWalletAddressNotAllowed && (
          <ExternalCorePassAddressVerificationAlert />
        )}
        {viewState === WithdrawalAllowanceStatusType.VerificationRequiredLtc && (
          <ExternalWalletAddressVerificationLTCAlert onClick={handleLTCInstructions} />
        )}
        {(isNil(viewState) || viewState === WithdrawalAllowanceStatusType.IsAllowed) && (
          <>
            <Button
              className={style['send-transaction__button']}
              type='secondary'
              design='general'
              size='large'
              uppercase
              htmlType='button'
              onClick={props.onCancel}
            >
              {t('CANCEL')}
            </Button>

            <Button
              className={style['send-transaction__button']}
              type='primary'
              design='general'
              size='large'
              uppercase
              disabled={
                !state.assetId ||
                !state.assetAmount ||
                !state.address ||
                state.assetAmount > state.balance ||
                !isEmpty(enteredAmountValidation) ||
                withdrawalIsAllowedWallet.isLoading
              }
              htmlType='submit'
            >
              {t('Send')}
            </Button>
          </>
        )}
      </footer>
    </form>
  );
};

export default SendTransaction;
